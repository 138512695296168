<template>
  <div>
    <customer-details>
      <template v-slot:btnPrope>
        <v-btn
          class="t-btn--prm mr-5 ml-2"
          :disabled="!checkPerUser"
        >
          {{ $t('task_29786.keep') }}
        </v-btn>
      </template>
      <div class="mt-3" style="width: 70%">
        <v-data-table
          calculate-widths
          disable-sort
          :headers="headers"
          :items="clientChangeHistoryListChangeHistory"
          hide-default-footer
          class="table-custom elevation-1"
        >
        <template v-slot:item.changedAt="{item}">
          <p>{{ item.changedAt | formatDateTime }}</p>
        </template>
        </v-data-table>
      </div>
    </customer-details>
  </div>
</template>
<script>
import CustomerDetails from '../CustomerDetails.vue';
import { mapActions, mapGetters } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions';
export default {
  components: { CustomerDetails },
  name: 'change-history',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      id: this.$route.params.id,
      headers: [
        {
          text: '日付',
          value: 'changedAt',
        },
        {
          text: 'ユーザー',
          value: 'changedByName',
          width:'150px'
        },
        {
          text: '設定',
          value: 'fieldName',
        },
        {
          text: '変更前',
          value: 'valueBefore',
        },
        {
          text: '変更後',
          value: 'valueAfter',
        },
      ],
      facilities: [],
    };
  },
  computed: {
    ...mapGetters(['clientChangeHistoryListChangeHistory']),
  },
  methods: {
    ...mapActions(['getClientChangeHistoryList']),
  },
  mounted() {
    this.getClientChangeHistoryList(parseInt(this.id));
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .table-custom {
    tr {
      th {
        padding-right: 0px !important;
        font-size: 15px !important;
        color: #424242 !important;
      }
      td {
        padding-right: 0px !important;
        color: #424242 !important;
        font-size: 16px !important;
      }
    }
  }
}
</style>
